import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Image } from 'rebass/styled-components'
import RestoSwiper from '../components/swiper/RestoSwiper'
import { connect, useSelector } from 'react-redux';


const Gallery = ({ data, imageFromStore }) => {
    const [media, setMedia] = useState({
        images: [],
        featured: '',
    })
    const [address, setAddress] = useState({
        line1: 'Address Line 1',
        city: 'City',
        country: 'Country',
    })
    useEffect(() => {
        if (data.media !== undefined) {
            setMedia({
                images: data.media.images,
                featured: data.media.images[0] && data.media.images[0].filepath + data.media.images[0].filename,
            })
        }
        if (data.address) {
            setAddress(data.address)
        }
    }, [data])
    // const featuredImage = useSelector( (state) => state.Restorer)
    const [featuredImage, setFeaturedImage] = useState(false)
    useEffect(() => {
        // if ( imageFromStore && imageFromStore.filename) {
        //     setFeaturedImage(imageFromStore)
        //     console.log('refiring useEffect')
        // }
        setFeaturedImage(imageFromStore)
    }, [imageFromStore])

    const baseUrl = process.env.GATSBY_CLOUDINARY_URL
    const logoPath = data.media && data.media.logo ? data.media.logo.filepath.replace(/[(]/g, '_').replace(/[)]/g, '') : null
    const logoName = data.media && data.media.logo ? data.media.logo.filename.replace(/[(]/g, '_').replace(/[)]/g, '') : null
    const logo = ( data.media && data.media.logo) ? <Image src={baseUrl + logoPath + logoName} width='206px' height='76px' sx={{ objectFit: 'cover', objectPosition: 'center' }}/> : null
    const filepath = (featuredImage) ? featuredImage.filepath.replace(/\s/g, '%20').replace(/[)]/g, '') : ''
    const filename = (featuredImage) ? featuredImage.filename.replace(/[(]/g, '%20').replace(/[)]/g, '') : media.featured
    const imageUrl = baseUrl + filepath + filename

    return (
        <>
            {data && (<>
                <Flex bg= 'charcoal' mt={['sm', 'sm', 'sm', 'sm', 'xxl']} px={['sm', 'sm', 'sm', 'sm', 'md']} py={['3', '3', '3', '3', '7']} flexDirection={['column', 'column', 'column', 'column', 'column', 'row']}>
                    <Box width='370px' mr='xl'>
                        <Box width='206px' mb='4'>
                            {logo}
                        </Box>
                        <Text variant='h1' as='h1' mb='xs'> {data.name}</Text>
                        <Text variant='boldCopy' as='h4' pb='sm' sx={{ borderBottom: '1px solid grey' }}>
                            {data.url}
                        </Text>
                        <Text my='sm' as='p' variant='boldCopy' color='aquaMedium'>
                            Address
                        </Text>
                        <Text variant='copy'lineHeight='120%' letterSpacing='3px' mb='sm'>
                            {address.line1}<br />{address.city}<br /> {address.country}
                        </Text>
                    </Box>
                    <Box display={['none', 'none', 'none', 'none', 'block']}>
                        <Image width={['100%', '100%', '100%', '100%', '100%', '620px', '929px']} src={imageUrl} height='447px' sx={{ objectFit: 'cover', objectPosition: 'center' }} />
                    </Box>
                </Flex>
                <RestoSwiper data={media.images}/>
            </>
            )}
        </>
    )
}

function mapStateToProps(state) {
    return {
        imageFromStore: state.Restorer.featured,
    };
}

export default connect(mapStateToProps)(Gallery);
