import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import Layout from '../../templates/Main'
import Gallery from '../../modules/Gallery'
import About from '../../modules/About'
import Brandmark from '../../assets/icons/brandmark-transparent.svg'


const RestorersPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Gallery />
            <Box my='18' sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: ['-140px', '-140px', '-140px', '-140px', '-175px']}}>
                    <Text variant='largeBg'>
                        CREMONINI
                    </Text>
                    <Box maxWidth='480px' sx={{ zIndex: '1', position: 'absolute' }}>
                        <Brandmark />
                    </Box>
                </Box>
                <Box sx={{ position: 'relative', zIndex: '10' }}>
                    <About />
                </Box>
            </Box>
        </Layout>
    )
}

export default RestorersPage
