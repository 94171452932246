import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import styled from 'styled-components'
import { Box, Image, Link } from 'rebass/styled-components'
import 'swiper/swiper.min.css'
import { setFeatured } from '../../system/redux/reducers/restorer'
import { connect } from 'react-redux';


const Container = styled(Swiper)`
    .swiper-container {
    height: 240px;
    }
    .swiper-scrollbar {
        background-color: ${(props) => props.theme.colors.greyDark};
    }
    .swiper-scrollbar-drag {
        background-color: ${(props) => props.theme.colors.aquaMedium};
        cursor: grab;
    }

    .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: ${(props) => props.theme.colors.aquaMedium};
    margin-bottom: 30px;
    }
    .swiper-slide img {
    width: 240px;
    height: 160px;
    object-fit: cover;
    object-position: center;
}

`

SwiperCore.use([Scrollbar]);

function SwiperFeed({ data, dispatch }) {
    const handleClick = (e, data) => {
        e.preventDefault()
        // console.log(data[1])
        dispatch(setFeatured(data[1]))
    }

    return (
        <Box bg='black'>
            {data.length>0 &&<Container
                longSwipesMs={20}
                shortSwipes={false}
                preventClicks={false}
                preventClicksPropagation={false}
                touchStartPreventDefault={false}
                observer
                observeParents
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 6,
                    },
                }}
                spaceBetween={4}
                scrollbar={{ dragSize: '26px', hide: false, draggable: true }}
                modules={[Scrollbar]}
                className="mySwiper">
                {
                    Object.entries(data).map((item) => {
                        const baseUrl = process.env.GATSBY_CLOUDINARY_URL
                        const filepath = item[1].filepath ? item[1].filepath.replace(/\s/g, '%20').replace(/[)]/g, '') : ''
                        const filename = item[1].filename ? item[1].filename.replace(/\s/g, '%20').replace(/[)]/g, '') : ''
                        const imageUrl = baseUrl + filepath + filename
                        return <SwiperSlide key={item[0]}>
                            <Link to='/'>
                                <Box onClick={(e) => handleClick(e, item)}>
                                    <Image src={imageUrl} sx={{ objectFit: 'cover', objectPosition: 'center', cursor: 'pointer' }} />
                                </Box>
                            </Link>
                        </SwiperSlide>
                    })
                }
            </Container>
            }
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
    };
}

export default connect(mapStateToProps)(SwiperFeed);
