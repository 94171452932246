import React, { useState, useEffect } from 'react'
import { Box, Flex, Image, Text } from 'rebass/styled-components'
import Map from '../assets/images/map.svg'

const About = ({ data }) => {
    const isSSR = typeof window === 'undefined'
    const [address, setAddress] = useState({
        line1: 'Address Line 1',
        line2: 'Address Line 2',
        city: 'City',
        country: 'Country',
        zip: 'Zip Code',
    })
    useEffect(() => {
        if (data.address) {
            setAddress(data.address)
        }
    }, [data])

    const uri =
        address.line1 +
        ' ' +
        address.line2 +
        ' ' +
        address.city +
        ' ' +
        address.zip
    const encoded = encodeURI(uri)
    return (
        <>
            {!isSSR && (
                <Flex
                    justifyContent='center'
                    flexDirection={[
                        'column-reverse',
                        'column-reverse',
                        'column-reverse',
                        'column-reverse',
                        'row',
                    ]}
                >
                    <Box mx='sm'>
                        <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${encoded}&zoom=13&scale=1&size=566x388&maptype=roadmap&key=${process.env.GATSBY_GOOGLE_API_KEY}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x77b7be%7Clabel:%7C${encoded}`}
                        />
                    </Box>
                    <Box mx='sm'>
                        <Text
                            as='h2'
                            variant='h2'
                            mb='md'
                            sx={{ textTransform: 'uppercase' }}
                        >
                            ABOUT {data.name}
                        </Text>
                        <Text
                            as='p'
                            variant='body'
                            maxWidth='564px'
                            height={['auto', 'auto', '325px']}
                            lineHeight='19px'
                            mb='lg'
                        >
                            {data.about}
                        </Text>
                    </Box>
                </Flex>
            )}
        </>
    )
}

export default About
